import {Component, Inject, OnInit} from '@angular/core';
import {DialogComponent} from "../../../../shared/components/dialog/dialog.component";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {DialogData} from "../../../../shared/models/dialog-data";


export class  CongratsPaymentDialogData extends DialogData {
  planName : string;
}

@Component({
    selector: 'congrats-payment-dialog',
    templateUrl: './congrats-payment-dialog.component.html',
    styleUrls: ['./congrats-payment-dialog.component.css'],
    standalone: true
})
export class CongratsPaymentDialogComponent extends DialogComponent implements OnInit {

  readonly mainImgUrl = '/img/congrats-payment.svg'

  constructor(
      @Inject(MAT_DIALOG_DATA) public data: CongratsPaymentDialogData,
  ) {
    super(data);
  }

  ngOnInit(): void {

  }



}
