
<div class="close-container">
    <div class="payment-dialog-close" (click)="close($event)"></div>
</div>
<form name="paymentForm" novalidate>
    <div class="group" *ngIf="data.onlyAskForCoupon">
        <div class="linked-card-container">
            <label class="bigvu-input-label">
                Card Information
            </label>
            <div class="linked-card-information" *ngIf="data.currentUserPlan?.credit">
                <i class="fa fa-credit-card" aria-hidden="true"></i>
                <div class="card-number">
                    {{data.currentUserPlan.credit.displayBrand}}
                    XXXX-XXXX-XXXX-{{data.currentUserPlan.credit.last4}}
                </div>
            </div>
            <div class="credit-card-actions">
                <div class="card-link-btn"
                        (click)="updateCard()">
                    Update card
                </div>
            </div>
        </div>
    </div>

    <ngx-spinner name="payment">
        <lottie-loader></lottie-loader>
    </ngx-spinner>

    <div class="group" [hidden]="data.onlyAskForCoupon">
        <label>
            <span>Cardholder name</span>
            <div id="paymentFullName"
                    [ngClass]="{'stripe-invalid': cardholderName.invalid && !cardholderName.pristine}">
                    <input 
                        required 
                        id="paymentFormFullName" 
                        class="field" 
                        [(ngModel)]="payment.name"
                        #cardholderName="ngModel"
                        name="cardholderName" 
                        min-length="2"
                        placeholder="Jane Doe"/>
            </div>
            <label 
                id="paymentFormFullName-error" 
                class="error"
                for="paymentFormFullName"
                [hidden]="cardholderName.valid || cardholderName.pristine">
                Cardholder name must be at least 2 characters long
            </label>
        </label>
    </div>
    <div class="group" [hidden]="data.onlyAskForCoupon">
        <label>
            <span>Card</span>
            <div id="card-number" class="field">
            </div>
        </label>
    </div>
    <div class="group" [hidden]="data.onlyAskForCoupon">
        <label class="card-left-input">
            <div id="card-expiration" class="field"></div>
        </label>
        <label class="card-right-input">
            <div id="card-security" class="field"></div>
        </label>
    </div>
    <div class="group" *ngIf="!data.justGetAToken && !data.hideCoupon">
        <label>
            <span>Coupon Code</span>
            <input 
                id="coupon" 
                class="field" 
                [(ngModel)]="payment.coupon"
                #coupon="ngModel"
                name="coupon"
                type="text" 
                placeholder="BIGVU5P"
                (change)="removeError()"/>
            <label 
                id="coupon-error" 
                class="error"
                for="coupon"
                [hidden]="errorMessage === ''">
                {{ errorMessage }}
            </label>
        </label>
    </div>
    <button 
        type="submit" 
        (click)="pay()" 
        [disabled]="submitting === true || (!data.onlyAskForCoupon && payment?.name?.length < 2)">
        <span *ngIf="paymentFormButtonText === undefined">
            Pay
            <span *ngIf="data.simplePlanName !== ''"> for
                <span>{{data.simplePlanName | titlecase}}</span>
                Plan
            </span>
        </span>
        <span *ngIf="paymentFormButtonText !== undefined">
            {{paymentFormButtonText}}
        </span>
    </button>
</form>