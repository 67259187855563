import {Injectable} from '@angular/core';
import {PaymentDialogComponent} from '../components/payment-dialog/payment-dialog.component';
import {DialogConfig, DialogHelperService} from '../../shared/services/dialog-helper.service';
import {PaymentDialogData} from '../models/payment-dialog-data';
import {PlanOffer} from '../models/plan-offer';
import {
    CongratsPaymentDialogComponent, CongratsPaymentDialogData
} from "../components/congrats-payment-dialog/congrats-payment-dialog/congrats-payment-dialog.component";
import {PlanHelperService} from "../../shared/services/plan-helper.service";
import {UserHelperService} from "../../shared/services/user-helper.service";
import {AdminRequestsService} from "../../shared/services/admin-requests.service";
import {EventsService} from "../../shared/services/events.service";


@Injectable({
    providedIn: 'root',
})
export class PaymentService {
    onSuccessfullPaymentCallback: Function;

    constructor(
        private dialogService: DialogHelperService,
        private planHelperService: PlanHelperService,
        private userHelperService: UserHelperService,
        private adminRequestsService: AdminRequestsService,
        private eventsService: EventsService,
    ) {

    }



    openPaymentDialog = (selectedOffer: PlanOffer, billingEmail: string, currentPlan: any, onSuccessfullPayment: Function, onlyAskForCoupon=false) => {
        const data = new PaymentDialogData();
        data.stripePlanName = selectedOffer.name
        data.simplePlanName = selectedOffer.simpleName;
        data.billingEmail = billingEmail;
        data.currentUserPlan = currentPlan;
        data.price = selectedOffer.price;
        data.onlyAskForCoupon = currentPlan && currentPlan.credit || onlyAskForCoupon;
        data.authority = currentPlan && currentPlan.authority;
        data.currency = selectedOffer.currency;
        data.tier = selectedOffer.simpleName === 'teams' ? (+selectedOffer.viewValue) - 3 + 1 : 0;
        data.onClose = this.dialogService.close;
        data.onSubmit = this.onPaymentDialogClose;
        const config = new DialogConfig();
        config.disableClose = true;
        config.panelClass = 'payment-dialog-container';
        this.dialogService.open(data, PaymentDialogComponent, config);

        this.onSuccessfullPaymentCallback = onSuccessfullPayment;
    }

    onPaymentDialogClose = (plan: any) => {
        this.planHelperService.clear();
        this.planHelperService.setPlanUpdated();
        this.userHelperService.getUserDetails(true);
        this.dialogService.close();
        this.onSuccessfullPaymentCallback && this.onSuccessfullPaymentCallback(plan);

        if(!this.planHelperService.isFreePlan(plan.genericName)) {
            this.openCongratsDialog(plan);
        }

    }

    openCongratsDialog = (plan: any) => {
        const data = new CongratsPaymentDialogData()
        data.planName = this.planHelperService.getPlanDisplayName(plan)
        data.onClose = this.dialogService.close;
        data.onSubmit = this.dialogService.close;
        const config = new DialogConfig();
        config.panelClass = 'congrats-payment-dialog-container';
        this.dialogService.open(data, CongratsPaymentDialogComponent, config);
    }

    openPaymentDialogForCardUpdate = (currentPlan: any) => {
        const data = new PaymentDialogData();
        data.justGetAToken = true;
        data.currentUserPlan = currentPlan;
        data.onClose = this.dialogService.close;
        data.onSubmit = this.onPaymentDialogClose;
        const config = new DialogConfig();
        config.panelClass = 'dialog-container';
        this.dialogService.open(data, PaymentDialogComponent, config);
        this.onSuccessfullPaymentCallback = null;
    }



    checkForSuccessPayment = async (params) : Promise<boolean> => {
        const sessionId = params['sessionId'];
        const dismiss = params['dismiss'];
        const planName = params['planName'];
        const currency = params['currency'];
        const price = params['price'];
        const paywallTrigger = params['paywallTrigger'];
        if (sessionId) {
            try {
                const plan = await this.adminRequestsService.endStripeSession(sessionId);
                const normPrice = price ? Number(price) : undefined;
                this.eventsService.log(this.eventsService.events.profile.buyPlan, {
                    authority: "STRIPE",
                    newPlan: plan.name,
                    newPlanTier: plan.display?.name,
                    newPlanDuration: `${plan.display?.duration?.length} ${plan.display?.duration?.unit}`,
                    currency: currency,
                    price: normPrice,
                    paywallTrigger: paywallTrigger || undefined
                });

                this.eventsService.log(this.eventsService.events.payment.checkoutCompleted, {
                    planName: planName,
                    currency: currency,
                    paywallTrigger: paywallTrigger || undefined
                });

                this.eventsService.logRevenue(plan.name, normPrice)

                if(!this.planHelperService.isFreePlan(plan.genericName)) {
                    this.openCongratsDialog(plan)
                    this.planHelperService.clear();
                    this.planHelperService.broadcastOrganizationPlan(plan)
                    this.planHelperService.setPlanUpdated();
                }

                return true;

            } catch (e) {
                console.error(e)
            }
        } else if (dismiss) {
            this.eventsService.log(this.eventsService.events.payment.checkoutDismissed, {
                planName: planName,
                currency: currency,
                paywallTrigger: paywallTrigger || undefined
            });

            return false;
        }
    }

}