import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ScriptLazyLoaderService {

  private scripts: any = {};


  constructor() { }

  loadScript(scriptSrc: string): Promise<void> {
    return new Promise((resolve, reject) => {
      // Check if script already loaded or cached
      if (this.scripts[scriptSrc] || this.isScriptCached(scriptSrc)) {
        console.log(`Script already loaded: ${scriptSrc}`);
        resolve();
      } else {
        // Load script dynamically
        console.log(`Loading script: ${scriptSrc}`);
        const script = document.createElement('script');
        script.src = scriptSrc;
        script.async = true;
        script.onload = () => {
          console.log(`Script loaded successfully: ${scriptSrc}`);
          this.scripts[scriptSrc] = true;
          resolve();
        };
        script.onerror = (error: any) => {
          console.log(`Error loading script: ${scriptSrc}`, error);
          reject(`Error loading script: ${scriptSrc} ${error}`);
        }
        document.head.appendChild(script);
      }
    });
  }

  private isScriptCached(scriptSrc: string): boolean {
    const scripts = document.getElementsByTagName('script');
    for (let i = 0; i < scripts.length; i++) {
      if (scripts[i].src === scriptSrc) {
        return true;
      }
    }
    return false;
  }
}

