import { Component, OnInit, Inject, AfterViewInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PaymentDialogData } from '../../models/payment-dialog-data';
import { environment } from '../../../environments/environment'
import { AdminRequestsService } from '../../../shared/services/admin-requests.service';
import { EventsService } from '../../../shared/services/events.service';
import { NgCookieService } from '../../../shared/services/ng-cookie.service';
import { SpinnerService } from '../../../shared/services/spinner.service';
import { DialogComponent } from '../../../shared/components/dialog/dialog.component';
import { ErrorParseService } from '../../../shared/services/error-parse.service';
import { DialogData } from '../../../shared/models/dialog-data';
import { DialogConfig, DialogHelperService } from '../../../shared/services/dialog-helper.service';
import { PlanOverrideDialogComponent } from '../plan-override-dialog/plan-override-dialog.component';
import {SessionService} from "../../../shared/services/session.service";
import {PlanHelperService} from "../../../shared/services/plan-helper.service";
import {NotificationsService} from "../../../shared/services/notifications.service";
import { LottieLoaderComponent } from '../../../shared/components/lottie-loader/lottie-loader.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgIf, NgClass, TitleCasePipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import {ScriptLazyLoaderService} from "../../../shared/services/script-lazy-loader.service";

declare let Stripe: any;

class Payment {
    constructor(
        public name: string,
        public card: string,
        public coupon: string
    ) {}
}

@Component({
    selector: 'payment-dialog',
    templateUrl: 'payment-dialog.component.html',
    styleUrls: ['payment-dialog.component.css'],
    standalone: true,
    imports: [FormsModule, NgIf, NgxSpinnerModule, LottieLoaderComponent, NgClass, TitleCasePipe]
})
export class PaymentDialogComponent extends DialogComponent implements AfterViewInit {
    stripeJS: any;
    stripeOptions: any;
    card: any;
    paymentFormButtonText: string;
    payment: Payment;
    isCouponValid: boolean;
    errorMessage: string;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: PaymentDialogData,
        private spinnerService: SpinnerService,
        private adminRequestsService: AdminRequestsService,
        private planHelperService: PlanHelperService,
        private eventsService: EventsService,
        private cookieService: NgCookieService,
        private errorParseService: ErrorParseService,
        private dialogService: DialogHelperService,
        private sessionService : SessionService,
        private notifier: NotificationsService,
        private stripeLoaderService: ScriptLazyLoaderService
    ) {
        super(data);
        this.payment = new Payment("", "", "");
        this.isCouponValid = true;
        this.errorMessage = "";
    }

    async ngAfterViewInit() {
        await this.loadStripe();
        if (!this.data.onlyAskForCoupon) {
            this.setPaymentFormElements()
        }
    }

    async loadStripe() {
        try {
            await this.stripeLoaderService.loadScript("https://js.stripe.com/v3/");
            // Stripe library has been successfully loaded, you can now use it
            // Initialize Stripe elements or perform other Stripe-related operations here
            this.stripeJS = Stripe(environment.globals.stripe);
            this.setStripeOptions();
            if (this.data.justGetAToken) {
                this.paymentFormButtonText = 'Link this card';
            }
        } catch (error) {
            console.error('Error loading Stripe:', error);
        }
    }


    updateCard = () => {
        this.data.onlyAskForCoupon = false;
        this.setPaymentFormElements();
    }

    setPaymentFormElements = () => {
        let elements = this.stripeJS.elements();
        this.card = elements.create('cardCvc', this.stripeOptions);
        this.card.mount('#card-security');
        this.card = elements.create('cardExpiry', this.stripeOptions);
        this.card.mount('#card-expiration');
        this.card = elements.create('cardNumber', this.stripeOptions);
        this.card.mount('#card-number');
    }

    pay = (force: boolean = false) => {
        this.submitting = true;
        this.errorMessage = "";

        if (!this.data.onlyAskForCoupon) {
            this.spinnerService.spin("payment");
            this.stripeJS.createToken(this.card, {name: this.payment.name})
                .then(result => {
                    if (result.token && !this.data.justGetAToken) {
                        return this.sendSubscriptionRequest(result.token.id, force);
                    } else if (result.token && this.data.justGetAToken) {
                        this.notifier.success('Card has linked successfully');
                        return  this.adminRequestsService.updatePlan({token : result.token.id}).then((res) => {
                            this.planHelperService.broadcastOrganizationPlan(res);
                            this.data.onSubmit(res);
                        }).catch(err => {
                            this.setErrorMessage(err.responseJSON.code);
                            this.notifier.error(this.errorMessage);
                        }).finally(() => {
                                this.spinnerService.stop("payment");
                                this.submitting = false;
                            });
                    } else if (result.error) {
                        console.log(result.error)
                        this.setErrorMessage(result.error.code)
                        this.submitting = false;
                    }
                })
                .catch(err => {
                    this.notifier.error("Failed to process payment. Please try again");
                    console.error(err);
                })
                .finally(() => {
                    this.spinnerService.stop("payment");
                })
        } else {
            this.sendSubscriptionRequest(undefined, force);
        }
    }
  
    sendSubscriptionRequest = (token?: any, force: boolean = false) => {
        this.eventsService.log(this.eventsService.events.profile.paymentComplete, {
            planName: this.data.simplePlanName,
            authority: "STRIPE"
        });

        let data = {
            billingEmail: this.data.billingEmail,
            planName: this.data.stripePlanName,
            currency : this.data.currency.toLowerCase()
        };

        // add the token if exists
        if (token) {
            data["token"] = token;
        }

        // add coupon if not empty
        if (this.payment.coupon && this.payment.coupon !== "") {
            data["coupon"] = this.payment.coupon;
        }

        data["force"] = force;

        if(this.data.tier > 0) {
            data["tier"] = this.data.tier;
        }


        let promise;
        if (this.data.currentUserPlan.name === 'free' ||
            (this.data.currentUserPlan.authority !== undefined &&
            this.data.currentUserPlan.authority !== "STRIPE")) {
            promise = this.adminRequestsService.subscribePlan(data);
        } else {
            promise = this.adminRequestsService.updatePlan(data);
        }

        this.spinnerService.spin("payment");
        return promise
            .then(res => {
                this.notifier.success('Payment complete');
                    const normPrice = this.data.price ? Number(this.data.price) : undefined;
                    this.eventsService.log(this.eventsService.events.profile.buyPlan, {
                    plan: this.data.currentUserPlan.name,
                    newPlanTier: res.display?.name,
                    newPlanDuration: `${res.display?.duration?.length} ${res.display?.duration?.unit}`,
                    authority: "STRIPE",
                    newPlan: this.data.stripePlanName,
                    currency: this.data.currency,
                    price: normPrice
                });
                this.eventsService.logRevenue(this.data.simplePlanName, normPrice);

                this.planHelperService.broadcastOrganizationPlan(res);
                this.data.onSubmit(res);
            }).catch(err => {
                if (err.responseJSON.code === "30013/0") {
                    this.openPlanOverrideDialog();
                }

                this.setErrorMessage(err.responseJSON.code);

                this.notifier.error(this.errorMessage);

                this.eventsService.log(this.eventsService.events.profile.paymentFailed, {
                    error: err,
                    authority: "STRIPE",
                    plan: this.data.simplePlanName
                });
            })
            .finally(() => {
                this.spinnerService.stop("payment");
                this.submitting = false;
            });
    }

    openPlanOverrideDialog = () => {
        const data = new DialogData();
        const planNameParts = this.data.currentUserPlan.name.split("_");
        data.header = planNameParts.slice(0, 3).join(" ").replace(/BIGVU/gi, "");
        data.onClose = () => {
            this.eventsService.log(this.eventsService.events.profile.subscriptionReplace, {
                "confirm": "false"
            });
            this.dialogService.close();
        }
        data.onSubmit = this.forcePay;

        const config = new DialogConfig();
        config.panelClass = 'dialog-container-large';

        this.dialogService.open(data, PlanOverrideDialogComponent, config);

        this.eventsService.log(this.eventsService.events.profile.planOverrideDialog);
    }

    forcePay = () => {
        this.eventsService.log(this.eventsService.events.profile.subscriptionReplace, {
            "confirm": "true"
        });

        this.dialogService.close();
        this.pay(true);
    }

    setStripeOptions = () => {
        this.stripeOptions =  {
            classes: {
                invalid: 'stripe-invalid'
            },
            style: {
                base: {
                    iconColor: '#666EE8',
                    color: '#31325F',
                    lineHeight: '40px',
                    fontWeight: 300,
                    fontFamily: '"Open Sans", Helvetica, sans-serif',
                    fontSize: '15px',
                    '::placeholder': {
                        color: '#CFD7E0',
                    },
                },
            }
        };
    }

    setErrorMessage = (errorCode: string) => {
        this.errorMessage = this.errorParseService.parseSubscriptionError(errorCode);
    }

    removeError = () => {
        this.isCouponValid = true;
        this.errorMessage = "";
    }

}
