import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { DialogComponent } from '../../../shared/components/dialog/dialog.component';
import { DialogData } from '../../../shared/models/dialog-data';

@Component({
    selector: 'plan-override-dialog',
    templateUrl: './plan-override-dialog.component.html',
    styleUrls: ['./plan-override-dialog.component.css'],
    standalone: true
})
export class PlanOverrideDialogComponent extends DialogComponent implements OnInit {

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
    ) {
        super(data);
    }

    ngOnInit() {

    }

}