<div class="congrats-payment-container">
    <div class="close-container">
        <div class="payment-dialog-close" (click)="close($event)"></div>
    </div>

    <img [src]="mainImgUrl" alt="image">

    <h3>
        Congratulations!
    </h3>
    <p>
        {{'You are now subscribed to ' + data.planName}}
    </p>

    <button class="done-btn" (click)="submit($event)">
        Continue
    </button>

</div>