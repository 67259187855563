<div class="large-dialog-header">
  <div class="plan-override-close-dialog-container">
    <button
      (click)="close()">
    </button>
  </div>
  <span>You already have a subscription to </span>
  <div class="plan-name-container">
    <img src="/img/checkmark-green.svg" alt="image"> <span> {{data.header}}</span>
  </div>
</div>
<div class="large-dialog-content">
  <div>This new subscription will replace your existing plan in your account.</div>
  <div class="button-green" (click)="submit($event)">add new subscription</div>
  <div class="note">
    <span class="bold">Important! </span>
    <span>Your current subscription is NOT cancelled automatically on the App Store. You need to cancel your original subscription directly in the App Store. You can easily manage your existing subscription on </span>
    <a href="https://play.google.com/store/account/subscriptions" target="_blank">Google</a> <span> / </span> <a href="https://support.apple.com/en-il/HT202039" target="_blank">Apple</a> <span>.</span>
  </div>
</div>
