import { DialogData } from "../../shared/models/dialog-data";

export class PaymentDialogData extends DialogData {
    public stripePlanName: string;
    public simplePlanName: string;
    public price: string;
    public currentUserPlan: any;
    public billingEmail: string;
    public hideCoupon: boolean;
    public onlyAskForCoupon: boolean;
    public justGetAToken: boolean;
    public authority: boolean;
    public currency : string;
    public tier : number;
}